@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 24px;
  gap: 8px;

  @media (--viewportMedium) {
    margin-top: 32px;
    flex-direction: row;
    gap: 0;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
  @media (max-width:767px) {
    width: calc(100% - 9px);
  }
}

.lastNameRoot {
  width: calc(50% - 9px);
  @media (max-width:767px) {
    width: calc(100% - 9px);
  }
}



.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  @media (max-width: 767px) {
    margin-top: 53px;
    & > div {
      margin-bottom: 20px;
    }
  }
  & button {
    font-size: 18px;
    font-weight: 600;
  }
}

.userTypeLabel {
  margin-top: 25px;
}
.selectRadioButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & .radioBox {
    margin-right: 20px;
    & label {
      color: #4a4a4a;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      letter-spacing: -0.305px;
      padding-top: 0;
    }
  }
}

.locationInput {
  composes: marketplaceModalPasswordMargins from global;
  & input {
    padding-left: 28px !important;
  }
}

.locationAutocompleteInputIcon {
  /* display: none; */
  position: absolute;
  top: 10px;
  left: 10px;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.smMargin {
  margin: 20px 0px !important;
  &.Smborder{
    @media (max-width:767px) {
     border-top: 1px solid #ddd;
     padding-top: 10px;
     margin: 20px 0px !important;
    }
   
   }
}

.locationAutocompleteInputWrapper {
  position: relative;
  & input {
    padding-left: 30px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 12px;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 0C2.46366 0 0 2.30393 0 5.14286C0 9.71429 5.5 16 5.5 16C5.5 16 11 9.71429 11 5.14286C11 2.30393 8.53634 0 5.5 0ZM5.5 8C5.06488 8 4.63953 7.86595 4.27775 7.61479C3.91596 7.36363 3.63398 7.00665 3.46746 6.58899C3.30095 6.17133 3.25738 5.71175 3.34227 5.26837C3.42716 4.82498 3.63669 4.4177 3.94437 4.09804C4.25204 3.77838 4.64404 3.56069 5.0708 3.47249C5.49756 3.3843 5.93991 3.42956 6.3419 3.60256C6.7439 3.77556 7.08749 4.06853 7.32923 4.44441C7.57097 4.8203 7.7 5.26221 7.7 5.71429C7.69936 6.32029 7.46737 6.90129 7.05493 7.3298C6.64249 7.75831 6.08328 7.99934 5.5 8Z' fill='%237C3AED'/%3E%3C/svg%3E%0A");
    z-index: 2;
    @media (max-width: 768px) {
      top: 10px;
      left: 10px;
    }
  }
  @media (max-width: 768px) {
    & input {
      padding: 6px 12px 4px 30px;
      height: auto;
    }
  }
}

.addButtonWrapper{
  display: flex;
  justify-content: flex-end;
}

.mapWrapper {
  height: 225px;
  background: #ddd;
  margin-top: 29px;
}

.row {
  margin-top: 22px;
  margin-bottom: 0;
  position: relative;
}

.removeButton {
  position: absolute;
  right: 0;
  border: 0;
  cursor: pointer;
  top: 0;
  &:hover {
    color: var(--colorFail);
  }
}

.addButton {
  min-height: unset;
  height: unset;
  padding: 0;
  width: max-content;
  margin-left: auto;
  color: #7c3aed;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.2px;
  text-decoration-line: underline;
  text-transform: capitalize;
  background-color: #fff;
  margin-top: 8px;
  &:hover {
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
}

.passwordWrapper {
  position: relative;
}

.passwordShow {
  position: absolute;
  cursor: pointer;
  top: 32px;
  right: 15px;
  z-index: 10;
  text-decoration: underline;
  color: var(--marketplaceColorLight);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;

  @media (--viewportMedium) {
    top: 45px;
  }
}